<template>
	<v-container
		id="Groups"
		fluid
		tag="section">
		<v-overlay
			z-index="60"
			v-model="loaderlay">
			<v-progress-circular
				indeterminate
				size="100"
				color="primary"
				class="l2">
				<v-progress-circular
					indeterminate
					size="75"
					color="primary"
					class="l3"></v-progress-circular>
			</v-progress-circular>
		</v-overlay>
		<v-row dense>
			<v-col>
				<v-card class="v-card--material true pa-3">
					<card-heading
						:color="sectionColor"
						:title="'Players'"></card-heading>
					<v-data-table
						must-sort
						:dense="dense"
						sort-by="player_mongo_id"
						:sort-desc="true"
						:headers="headers"
						:items="list"
						:item-class="itemClass"
						:options.sync="options"
						hide-default-footer
						:server-items-length="options.itemsPerPage"
						:loading="loading"
						:footer-props="footerProps"
						class="elevation-1"
						@click:row="handleClick">
						<template #top="{ options, updateOptions }">
							<div class="d-flex justify-space-between align-center">
								<v-form
									ref="search"
									class="col-3 ml-6 pa-0"
									v-model="valid">
									<v-row
										align="center"
										class="pa-0">
										<v-tooltip
											color="primary darken-2"
											bottom>
											<template v-slot:activator="{ on, attrs }">
												<span
													v-bind="attrs"
													v-on="on">
													<v-icon
														size="large"
														:color="`primary ${
															$vuetify.theme.dark ? '' : 'darken-2'
														}`"
														class="mr-1">
														mdi-help-circle
													</v-icon>
												</span>
											</template>
											<span>
												Search Key can be either:
												<br />
												Player Nick or Internal Player ID
											</span>
										</v-tooltip>
										<v-text-field
											label="Search"
											v-model="searchKey"
											:dark="$vuetify.theme.dark"
											:light="!$vuetify.theme.dark"
											:error-messages="searchErrs"
											v-if="
												_usrFlagsSome({
													key: `player.detail`,
													val: permissions.READ,
												})
											"
											@blur="
												() => {
													valid = true;
													(searchRules = []), (searchErrs = []);
												}
											"
											@input="
												() => {
													valid = true;
													(searchRules = []), (searchErrs = []);
												}
											"
											@keydown.enter.prevent="
												(e) =>
													onSubmit(
														searchKey,
														loadPlayerSearch,
														playerSearch,
														handleClick,
														e
													)
											"
											:rules="searchRules"></v-text-field>
									</v-row>
								</v-form>
								<InfinitePagination
									class="mr-4"
									:footer="footerProps"
									:dense.sync="dense"
									:options="options"
									@update:options="updateOptions"
									@pagination="mxPaginationHandler"></InfinitePagination>
							</div>
						</template>
						<template v-slot:[`item.sum_bet`]="{ item }">
							{{ item.sum_bet | Number({ currency: item.currency }) }}
						</template>
						<template v-slot:[`item.sum_win`]="{ item }">
							{{ item.sum_win | Number({ currency: item.currency }) }}
						</template>
						<template #foot="{ options, updateOptions }">
							<tfoot>
								<tr>
									<td colspan="100%">
										<div class="d-flex justify-end">
											<InfinitePagination
												:footer="footerProps"
												:dense.sync="dense"
												:options="options"
												@update:options="updateOptions"
												@pagination="mxPaginationHandler"></InfinitePagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CardHeading from "../../components/shared/CardHeading.vue";
import permissions from "../../mixins/permissions";
import { rules as defaultRules } from "../../plugins/rules.ts";
import table2 from "../../mixins/table2";
import InfinitePagination from "../../components/shared/InfinitePagination.vue";

export default {
	components: {
		// Table,
		CardHeading,
		InfinitePagination,
	},
	mixins: [permissions, table2],
	data() {
		return {
			loaderlay: false,
			searchKey: "",
			valid: false,
			searchRules: [],
			searchErrs: [],
			rules: {
				...defaultRules,
			},
			sectionColor: "secondary",
			headers: [
				{ text: "Player ID", value: "player_mongo_id", align: "center" },
				{ text: "Nick", value: "nick", align: "center" },
				{ text: "Σ Bet", value: "sum_bet", align: "right" },
				{ text: "Σ Win", value: "sum_win", align: "right" },
				{ text: "Session Count", value: "sessions_count", align: "center" },
				{ text: "Round Count", value: "games_count", align: "center" },
			],
		};
	},
	watch: {
		currentCasino() {
			if (this.options.page === 1) {
				this._loadTable();
			} else {
				this.options.page = 1;
			}
		},
	},
	computed: {
		...mapGetters(["currentCasino", "playerSearch"]),
		rowIsClickable() {
			return this._usrFlagsSome({
				key: "player.detail",
				val: this.permissions.READ,
			});
		},
		...mapGetters("players2", {
			list: "list",
			isFirst: "isFirst",
			isLast: "isLast",
		}),
	},
	methods: {
		...mapActions(["loadPlayerSearch"]),
		...mapActions("players2", {
			loadList: "loadList",
			next: "next",
			previous: "previous",
			last: "last",
			first: "first",
		}),
		...mapMutations("players2", {
			setPagination: "pagination",
		}),
		async onSubmit(val, searchFn, searchGetter, routingFn, e) {
			this.searchRules = [this.rules.required];

			this.$refs.search.validate();
			await this.$nextTick();

			if (!this.valid) return;

			this.loaderlay = true;
			await searchFn({ searchKey: val, noErr: true });

			if (!searchGetter?.data) {
				this.loaderlay = false;
				this.searchErrs = ["Specified player does not exist."];
			}

			if (searchGetter?.data?.length === 1) {
				this.loaderlay = false;
				await routingFn(searchGetter.data[0], undefined, e);
				return;
			}
			this.loaderlay = false;
		},
		async handleClick(item, _, e) {
			if (!this.rowIsClickable) return;
			if (e.ctrlKey === true || e.metaKey === true) {
				const tempRoute = this.$router.resolve({
					name: "player-detail",
					params: {
						player_id: item.player_mongo_id,
						casino: this.currentCasino,
						breadcrumbs: { title: `Player ${item.player_mongo_id}` },
					},
				});
				window.open(tempRoute.href);
			} else {
				try {
					await this.$router.push({
						name: "player-detail",
						params: {
							player_id: `${item.player_mongo_id}`,
							breadcrumbs: { title: `Player ${item.player_mongo_id}` },
							casino: this.currentCasino,
						},
					});
				} catch (error) {
					this.loaderlay = false;
				}
			}
		},
	},
};
</script>

<style scoped lang="sass">
::v-deep
	@for $i from 1 through 3
		& .l#{$i}>svg
			$dur:4s
			$offset: .3
			$delay: ($i * $offset) - 3s
			animation-duration: $dur
			animation-delay: $delay
			animation-direction: alternate
			animation-timing-function: ease-in-out
			@if $i % 2 == 0
				animation-direction: alternate-reverse
			.v-progress-circular__overlay
				animation-duration: $dur
				animation-delay: $delay
				animation-direction: alternate
				animation-timing-function: ease-in-out
				@if $i % 2 == 0
					animation-direction: alternate-reverse
</style>
