import localstorage from "./localstorage";
import { mapActions } from "vuex";
import qs from "qs";
import { inflate } from "pako";

export default {
	mixins: [localstorage],
	data() {
		return {
			dense: false,
			footerProps: {
				"items-per-page-options": [5, 10, 15, 25, 50, 100],
				showFirstLastPage: true,
				firstIcon: "mdi-arrow-collapse-left",
				lastIcon: "mdi-arrow-collapse-right",
				prevIcon: "mdi-minus",
				nextIcon: "mdi-plus",
			},
			hideDefaultFooter: true,
			blockAutoLoad: false,
			options: {},
			total_records: 0,
			loading: false,
			footerStorage: {},
			loadingCurrentQuery: false,
		};
	},
	computed: {
		filteredHeaders() {
			return this.headers.filter((el) => !el.hide?.());
		},
	},
	methods: {
		...mapActions("notification", ["setMessage"]),
		itemClass() {
			return `table-item${this.rowIsClickable ? " table-item--clickable" : ""}`;
		},
		async mxPaginationHandler(e, val) {
			this.loading = true;
			await this[`${e}`](val);
			this.loading = false;
		},
		async _loadTable(url) {
			this.loading = true;
			try {
				if (!this.loadList) {
					this.loading = false;
					return;
				}
				await this.loadList({ url });
				this.loading = false;
			} catch (e) {
				if (e?.message === "canceled") {
					return;
				}
				this.loading = false;
			}
		},
	},
	async created() {
		this.$syncAndAssign(this.$route.name, "dense");
		this.$syncWithStorage("options", this.$route.name);
		this.$syncWithStorage("query", this.$route.name);

		const tempOpt = this.$getFromStorage("options");
		if (tempOpt) {
			delete tempOpt.page;
			this.options = tempOpt;
		}

		const current = this.$getFromStorage("query")?.current;

		this.$watch(
			"options",
			async (newVal) => {
				console.log("options changed");
				await this.setPagination({
					...this.getPagination,
					per_page: newVal.itemsPerPage,
					order: newVal.sortDesc?.[0] ? "-" : "+",
					order_by: newVal?.sortBy?.[0],
				});

				if (this.blockAutoLoad || this.loadingCurrentQuery) {
					console.error(
						"Blocked by Autoload Prevention or current query loading"
					);
					return;
				} else {
					await this._loadTable();
				}
			},
			{ deep: true }
		);

		if (current) {
			this.loadingCurrentQuery = true;
			if (
				current &&
				qs.parse(current).pagination.token &&
				qs.parse(current).pagination.token !== "None"
			) {
				this.setMessage({
					message: "Previous pagination was restored.",
					type: "info",
					duration: 2,
				});
			}
			await this._loadTable(current);
			this.loadingCurrentQuery = false;
		}
	},
};
